<template>
    <div class="login-container">
        <h2 class="title"><i class="icon-lock-open-1"></i>{{$t('user.findPwd')}}</h2>
        <form @submit.prevent>
            <div class="form-item">
                <input type="text" v-model="user.email" :placeholder="$t('user.email')">
                <transition name="fade-in">
                    <p class="error-tips" v-if="errorTips.email">{{errorTips.email}}</p>
                </transition>
            </div>

            <div class="form-item">
                <button v-if="computeTime>0" class="button long" disabled>{{$t('user.emailHasBeenSent')}} {{computeTime}}</button>
                <button v-else class="button long" @click="getCode">{{$t('common.submit')}}</button>
            </div>
        </form>
    </div>
</template>
<script>
import schema from 'async-validator';
import { checkEmail } from '../services/user';
const emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export default {
    name: 'login',
    data() {
        const descriptor = {
            email: [{
                type: 'string',
                required: true,
                message: this.$t('user.enterEmail'),
            }, {
                pattern: emailReg,
                message: this.$t('user.enterLegalEmail'),
            }],
        }
        return {
            user: {
                email: '',
            },
            computeTime:0,
            validator: new schema(descriptor),
            errorTips: {
                email: '',
            }
        }
    },
    methods: {
        async getCode(){
            if(!this.computeTime){
                this.computeTime=60
                const internalId=setInterval(()=>{
                    this.computeTime--;
                    if(this.computeTime<=0){
                        clearInterval(internalId)
                    }

                },1000)
            }
            this.submit()
        },
        async submit() {
            this.validator.validate(this.user).then(async () => {
                checkEmail(this.user)
                .then(res => {
                    if(res.code === 0) {
                        console.log(res)
                        this.$success({
                            title: this.$t('user.resetEmail')
                        });
                    }
                })
            }).catch(({ errors, fields }) => {
                let errorTips = {}
                errors.forEach(error => {
                    if(!errorTips[error.field]) {
                        errorTips[error.field] = error.message;
                    }
                })
                this.errorTips = errorTips;
            })
        }
    }
}
</script>
<style lang="less" scoped>
@import '../assets/css/theme.less';
.login-container {
    width: 500px;
    margin: 60px auto;
    border: 1px solid #f1f1f1;
    border-radius: 3px;
    h2 {
        font-size: 20px;
        padding: 20px 40px;
        margin-bottom: 20px;
        i {
            margin-right: 10px;
        }
    }
}
.form-item {
    padding: 0 40px;
    margin-bottom: 20px;
    position: relative;
    input {
        width: 100%;
        padding: 0 20px;
        background-color: #fff;
        height: 40px;
        border: 1px solid #dcdfe6;
        border-radius: 4px;
        &:-webkit-autofill {
            box-shadow: 0 0 0px 1000px white inset !important;
        }
        &:focus {
            border-color: @primary-color;
        }
    }
    .error-tips {
        position: absolute;
        left: 40px;
        font-size: 12px;
        padding-top: 4px;
        color: @red-color;
    }
}
.long {
    width: 100%;
    margin: 0;
}
.forgot-password {
    &:hover {
        color: @primary-color;
    }
}

.button {
    margin: 0;
}
</style>
